const userMenu = {
  'userMenu.english': 'Ingles',
  'userMenu.portuguese': 'Portugués',
  'userMenu.spanish': 'Español',
  'userMenu.notifications': 'Notificações',
  'userMenu.help': 'Ajuda',
  'userMenu.exit': 'Sair',
  'userMenu.profile': 'Perfil',
  'userMenu.language': 'Idioma',
};

export default userMenu;
