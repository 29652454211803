const profileParameters = {
  'profile.avaliable': 'Limite disponível',
  'profile.minimum_payment': 'Mínimo',
  'profile.minimum_payment_short': 'Mín',
  'profile.due_date': ' Vencimento',
  'profile.MDR': 'taxa Pismo',
  'profile.CASHBACK': 'Cashback',
  'profile.DESCONTO PISMO': 'Desconto Pismo',
  'profile.delay': 'Pagamento pendente',
  'profile.best_transaction_day': 'Melhor dia para compra',
  'profile.credits': 'Saldo em pontos',
  'profile.monthly': 'Limite parcela',
  'profile.change': 'Alterar',
  'profile.pay': 'Pagar',
  'profile.current_balance': 'Saldo atual',
  'profile.next_invoice': 'Próxima fatura',
  'profile.current_invoice': 'Fatura atual',
  'profile.menu.cards': 'Cartões',
  'profile.menu.data': 'Dados',
  'profile.menu.spendingLimits': 'Limites de gastos',
  'profile.menu.limits': 'Limites',
  'profile.menu.account': 'Conta',
  'profile.status.NORMAL': 'Normal',
  'profile.status.CANCELLED': 'Cancelada',
  'profile.status.BLOCKED': 'Obstruido',
  'profile.parameter.10': 'Tarifa Extrato Correio',
  'profile.parameter.24271': 'Valor anuidade',
  'profile.parameter.28871': 'Valor anuidade',
  'profile.parameter.24272': 'Parcelas anuidade',
  'profile.parameter.24273': 'Tarifa fatura atraso',
  'profile.parameter.24274': 'Tarifa Overlimit',
  'profile.parameter.24270': 'Tarifa Extrato E-mail',
  'profile.parameter.28870': 'Tarifa Extrato E-mail',

  'profile.limitProposal': 'Alteração de limite',
  'profile.creditLimit': 'Limite de crédito',
  'profile.currentLimit': 'Limite atual',
  'profile.pendingLimitProposal':
    'Já existe um pedido de aumento {br} de limite em andamento de ',
  'profile.parameter.success': 'Salvo com sucesso',
  'profile.limitProposal.selectReason': 'Motivo da alteração',
  'profile.limitProposal.reasons.altIncome': 'Nova fonte de renda',
  'profile.limitProposal.reasons.income': 'Aumento de salário',
  'profile.limitProposal.reasons.retirement': 'Pensão/Aposentadoria',
  'profile.limitProposal.reasons.spending': 'Aumento de consumo',
  'profile.limitProposal.reasons.trip': 'Viagem',
  'profile.limitProposal.reasons.other': 'Outro',
  'profile.limit.outcome.failure':
    'A alteração de limite falhou, tente novamente mais tarde.',
  'profile.limit.outcome.success': 'Alteração de limite realizada com sucesso.',
  'profile.limitProposal.exceedMaxLimitDisclaimer':
    'Este valor excede o máximo pré-aprovado no momento. Se você deseja enviar a solicitação para análise, escolha um motivo abaixo.',
  'profile.recharge': 'Recarregar',
  'profile.account_status': 'Status da conta',
  'profile.account_status.FULL_CANCELLATION': 'Encerramento de conta',
  'profile.account_status.ACCOUNT_CANCELLATION': 'Bloqueada',
  'profile.account_status.ACCOUNT_BLOCK': 'Bloqueio de conta',
  'profile.account_status.USER_CANCELLATION': 'Usuário cancelado',
  'profile.account_status.JUDICIAL_BLOCK': 'Bloqueio judicial',
  'profile.account_status.JUDICIAL_UNLOCK': 'Desbloqueio judicial',
  'profile.account_status.NORMAL': 'Ativa',
  'profile.account_status.ACTIVE': 'Ativa',
  'profile.account_status.DORMANT': 'Dormente',
  'profile.changeStatus': 'Alterar status',
  'profile.changeStatus.successTitle': 'Sucesso',
  'profile.changeStatus.successMessage':
    'Status da conta atualizado com sucesso',
  'profile.changeStatus.rejectedTitle': 'Rejeitado',
  'profile.changeStatus.rejectedMessage':
    'Atualização do status da conta rejeitada',
  'profile.limits.noLimits': 'Sem limites',
  'profile.limits.noBankSlipLimits': 'Sem limites para boletos',
  'profile.limits.noPaymentRequests': 'Sin solicitudes de pago',
  'profile.age': 'Idade',
  'profile.account_number': 'Número da conta',
  'profile.client_since': 'Cliente desde',
  'profile.password_status': 'Status da senha',
  'profile.password_status_history': 'Histórico',
};

export default profileParameters;
