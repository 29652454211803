const formLabels = {
  'formLabels.submit': 'Salvar',
  'formLabels.add': 'Adicionar',
  'formLabels.remove': 'Remover',
  'formLabels.closeAccount': 'Encerrar Conta',
  'formLabels.yes': 'Sim',
  'formLabels.no': 'Não',
  'formLabels.name': 'Nome',
  'formLabels.document_number': 'CPF',
  'formLabels.email': 'E-Mail',
  'formLabels.marital_status': 'Estado Civil',
  'formLabels.marital_status.single': 'Solteiro',
  'formLabels.marital_status.married': 'Casado',
  'formLabels.marital_status.divorced': 'Divorciado',
  'formLabels.marital_status.widower': 'Viúvo',
  'formLabels.gender': 'Sexo',
  'formLabels.gender.f': 'Feminino',
  'formLabels.gender.m': 'Masculino',
  'formLabels.countryCode': 'Código de país',
  'formLabels.areaCode': 'Código de área',
  'formLabels.phone': 'Telefone',
  'formLabels.phoneMessage': 'Sms',
  'formLabels.extension': 'Ramal',
  'formLabels.phone.residential': 'Residencial',
  'formLabels.phone.commercial': 'Comercial',
  'formLabels.phone.mobile': 'Celular',
  'formLabels.phone.all': 'Todos',
  'formLabels.phonePlaceholder': '(11) 99999-8888',
  'formLabels.type': 'Tipo',
  'formLabels.zipcode': 'CEP',
  'formLabels.address': 'Endereço',
  'formLabels.number': 'Número',
  'formLabels.complementary_address': 'Complemento',
  'formLabels.main': 'Principal',
  'formLabels.country': 'País',
  'formLabels.state': 'Estado',
  'formLabels.city': 'Cidade',
  'formLabels.neighborhood': 'Bairro',
  'formLabels.personalData': 'Perfil',
  'formLabels.phones': 'Telefones',
  'formLabels.addresses': 'Endereços',
  'formLabels.address.residential': 'Endereço residencial',
  'formLabels.address.commercial': 'Endereço comercial',
  'formLabels.address.other': 'Outro endereço',
  'formLabels.address.all': 'Todos',
  'formLabels.zip_code': 'CEP',
  'formLabels.card_name': 'Nome do cartão',
  'formLabels.transaction_limit': 'Limite por transação',
  'formLabels.currentPassword': 'Senha atual',
  'formLabels.newPassword': 'Nova senha',
  'formLabels.newPasswordConfirmation': 'Nova senha (confirmação)',
  'formLabels.mailingAddress': 'Correspondência',
  'formLabels.titleAddressHistoryChangesModal':
    'Hitórico de alterações do endereço',
  'formLabels.addressCreationDate': 'Data de criação',
  'formLabels.addressLastUpdate': 'Última atualização',
  'formLabels.noAddressRegistered': 'Nenhum endereço cadastrado',
  'formLabels.titlePhoneHistoryChangesModal':
    'Hitórico de alterações do telefone',
  'formLabels.phoneCreationDate': 'Data de criação',
  'formLabels.phoneLastUpdate': 'Última atualização',
  'formLabels.noPhoneRegistered': 'Nenhum telefone cadastrado',
  'formLabels.genericErrorMsg': 'Um erro ocorreu. Por favor tente mais tarde.',
  'formLabels.active': 'Ativo',
  'formLabels.inactive': 'Inativo',
  'formLabels.status': 'Status',
  'formLabels.comment': 'Comentário',
  'formLabels.all': 'Todos',
  'formLabels.reason': 'Motivo',
  'formLabels.choose': 'Escolha',
  'formLabels.status.full_cancellation': 'Encerramento de conta',
  'formLabels.status.account_cancellation': 'Cancelamento de conta',
  'formLabels.status.account_block': 'Bloqueio de conta',
  'formLabels.status.user_cancellation': 'Cancelamento do usuário',
  'formLabels.status.judicial_block': 'Bloqueio',
  'formLabels.status.judicial_unlock': 'Desbloqueio',
  'formLabels.status.normal': 'Ativa',
  'formLabels.status.block_judicial_blocked': 'Bloqueio Judicial',
  'formLabels.customerChangeStatus': 'Status da conta',
  'formLabels.birth_date': 'Data de nascimento',
  'formLabels.profile_age': 'Idade',
  'formLabels.city_of_birth': 'Cidade de nascimento',
  'formLabels.state_of_birth': 'UF de nascimento',
  'formLabels.country_of_birth': 'País de nascimento',
  'formLabels.document_type': 'Documento',
  'formLabels.document_type.rg': 'RG',
  'formLabels.document_type.cnh': 'CNH',
  'formLabels.other_id_number': 'Número',
  'formLabels.document_issued_at': 'UF',
  'formLabels.document_issued_by': 'Órgão expedidor',
  'formLabels.document_issued_date': 'Data emissão',
  'formLabels.mothers_name': 'Nome da mãe',
  'formLabels.fathers_name': 'Nome do pai',
  'formLabels.occupation': 'Profissão',
  'formLabels.income': 'Renda',
  'formLabels.social_name': 'Razão social',
  'formLabels.company_document_number': 'CNPJ',
  'formLabels.company_registration_number': 'Nº de inscrição',
  'formLabels.company_activity': 'Atividade principal',
  'formLabels.company_type': 'Natureza jurídica',
  'formLabels.company_format': 'Forma de constituição',
  'formLabels.company_constitution_date': 'Data de constituição',
  'formLabels.annual_revenues': 'Faturamento médio',
  'formLabels.company_pep': 'PEP',
  'formLabels.fiscal_situation': 'Situação fiscal',
  'formLabels.number_of_partners': 'Número de sócios',
  'formLabels.perc_ownership': 'Participação societária',
  'formLabels.net_worth': 'Patrimônio',
};

export default formLabels;
