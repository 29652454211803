const cards = {
  'cards.types.regular': 'Adicional',
  'cards.types.subscription': 'Assinatura',
  'cards.types.recurrence': 'Recorrente',
  'cards.types.ecommerce': 'E-commerce',
  'cards.types.PLASTIC': 'Físico',
  card: 'Cartão',
  'cards.types.TEMPORARY': 'E-commerce',
  'cards.types.CARD_ON_FILE': 'Recorrente',
  'cards.types.RECURRING': 'Assinatura',

  'cards.form.newCard': 'Novo cartão',
  'cards.form.holderName.placeholder': 'Nome completo do usuário *',
  'cards.form.printedName.placeholder': 'Nome impresso no cartão *',
  'cards.form.birthdate.placeholder': 'Nascimento (ex.: 04/11/1986) *',
  'cards.form.gender.placeholder': 'Sexo *',
  'cards.form.submit': 'Criar',

  'cards.params.verification': 'Verificação',
  'cards.params.not_verificated': 'Não verificado',
  'cards.params.effective': 'Efetivada',
  'cards.params.printed_name': 'Usuário',
  'cards.params.stage': 'Status adicional',
  'cards.params.status': 'Status',
  'cards.params.issuing_date': 'Data de emissão',
  'cards.params.name': 'Nome do cartão',
  'cards.params.type': 'Tipo',
  'cards.params.transaction_limit': 'Limite por transação',
  'cards.params.expiration_date': 'Data de validade',
  'cards.params.network': 'Bandeira',
  'cards.params.last_4_digits': 'Cartão',
  'cards.params.creation_date': 'Asssociado em',
  'cards.params.issuer_card': 'Cartão Pismo',
  'cards.params.card_profile': 'Cor',
  'cards.params.card_name': 'Apelido',
  'cards.params.document_number': 'Número do Documento',
  'cards.params.true': 'Sim',
  'cards.params.false': 'Não',

  'cards.params.CREATED': 'Criado',
  'cards.params.ACTIVE': 'Ativo',
  'cards.params.SENT': 'Enviado',
  'cards.params.UNBLOCKED': 'Desbloqueado',
  'cards.params.SUSPENDED': 'Suspenso',
  'cards.params.PENDING': 'Pendente',
  'cards.params.POSTED': 'Postado',
  'cards.params.TRANSFER': 'Transferir',
  'cards.params.REDIRECT_BY_THIRD': 'Redirecionado por terceiros',
  'cards.params.DELIVERED_BY_THIRD': 'Entregue por terceiros',
  'cards.params.RETURNED_BY_THIRD': 'Retornado por terceiros',
  'cards.params.DELIVERY_IN_PROGRESS': 'Entrega em progresso',
  'cards.params.NOT_DELIVERED': 'Não entregue',
  'cards.params.ARRIVED': 'Entregue',
  'cards.params.UNRECEIVED': 'Não recebido',
  'cards.params.REGISTERED': 'Registrado',
  'cards.params.CUSTODY': 'Em custódia',
  'cards.params.DESTRUCTION': 'Em destruição',
  'cards.params.ENABLE_RESEND': 'Reenvio habilitado',

  'cards.params.NORMAL': 'Normal',
  'cards.params.BLOCKED': 'Bloqueado',
  'cards.params.CANCELED': 'Cancelado',
  'cards.params.DAMAGED': 'Danificado',
  'cards.params.EXPIRED': 'Expirado',
  'cards.params.FRAUD': 'Fraudado',
  'cards.params.LOST': 'Perdido',
  'cards.params.ROBBED': 'Roubado',
  'cards.params.WARNING': 'Preventivo',
  'cards.params.REISSUED': 'Reemitido',
  'cards.params.DELETED': 'Deletado',
  'cards.params.BROKEN': 'Quebrado',
  'cards.params.OVERDUE': 'Vencido',
  'cards.params.INOPERATIVE': 'Inoperante',

  'cards.changeStatus': 'Alterar status',
  'cards.newCard': 'Segunda via',
  'cards.unblockTitle': 'Deseja confirmar o desbloqueio?',
  'cards.statusChangeTitle': 'Motivo da alteração',

  'cards.newCard.title': 'Segunda via',
  'cards.newCard.addressTitle': 'Endereço de entrega',
  'cards.newCard.reason': 'Motivo da alteração',
  'cards.newCard.selectReason': 'Selecione um motivo',
  'cards.newCard.costExplanation': 'Para esse motivo será cobrado',

  'cards.selectStatus': 'Selecione um status',
  'cards.statuses.BLOCKED': 'Bloqueado',
  'cards.statuses.CANCELED': 'Cancelado',
  'cards.statuses.DAMAGED': 'Danificado',
  'cards.statuses.EXPIRED': 'Expirado',
  'cards.statuses.FRAUD': 'Fraudado',
  'cards.statuses.LOST': 'Perdido',
  'cards.statuses.NORMAL': 'Normal',
  'cards.statuses.ROBBED': 'Roubado',
  'cards.statuses.WARNING': 'Preventivo',
  'cards.statuses.REISSUED': 'Reemitido',
  'cards.statuses.DELETED': 'Deletado',
  'cards.statuses.BROKEN': 'Quebrado',
  'cards.statuses.LOSS': 'Perda',
  'cards.statuses.CLIENT_ORDER': 'A pedido do cliente',
  'cards.statuses.DEFECT': 'Defeito',
  'cards.statuses.INCORRECT_NAME': 'Nome incorreto',
  'cards.statuses.RELIEF_LOSS': 'Perda de relevo',
  'cards.statuses.RENAME': 'Troca do nome',
  'cards.statuses.SHAVED': 'Cartão rasurado',
  'cards.statuses.THEFT': 'Furto',
  'cards.statuses.UNBOUND': 'Remoção de adicional',
  'cards.statuses.UNRECEIVED': 'Não recebido',

  'card.delete': 'Excluir cartão',
  'cards.STRIKETHROUGH': 'Cartão rasurado',
  'cards.notReceived': 'Não recebi o cartão',
  'cards.notWorking': 'Cartão não está funcionando',
  'cards.isDefective': 'Cartão com defeito',
  'cards.reliefLoss': 'Perda de relevo',
  'cards.nameChange': 'Troca de nome',
  'cards.userRequest': 'Pedido do cliente',

  'card.seeAccount': 'Ver conta',
  'card.physical': 'Físico',
  'card.virtual': 'Virtual',
  'cards.deleteCard.confirm':
    'Uma vez excluído, o cartão não poderá ser recuperado.',
  'cards.newCard.delete.failure': 'Falha ao excluir cartão.',
  'cards.newCard.delete.success': 'Cartão excluído com sucesso.',
  'cards.newCard.success': 'Cartão gerado com sucesso!',
  'cards.newCard.failure': 'Não foi possível gerar o novo cartão no momento.',
  'cards.unblock.success': 'Cartão desbloqueado com sucesso!',
  'cards.unblock.failure': 'Não foi possível desbloquear o cartão no momento.',
  'cards.statusChange.success': 'Status do cartão foi alterado com sucesso!',
  'cards.statusChange.failure': 'Não foi possível alterar o status do cartão.',

  'cards.definitiveBlock': 'Bloqueio definitivo',
  'cards.temporaryBlock': 'Bloqueio temporário',
  'cards.temporaryBlock.title': 'Deseja confirmar o bloqueio?',
  'cards.temporaryBlock.success': 'O cartão foi bloqueado com sucesso!',
  'cards.temporaryBlock.failure':
    'Não foi possível bloquear o cartão no momento.',

  'cards.temporaryUnblock': 'Desbloquear',
  'cards.temporaryUnblock.title': 'Deseja confirmar o desbloqueio?',
  'cards.temporaryUnblock.success': 'O cartão foi desbloqueado com sucesso!',
  'cards.temporaryUnblock.failure':
    'Não foi possível desbloquear o cartão no momento.',

  'cards.editParam': 'Editar parâmetro do cartão',
  'cards.editParam.successSingular': 'foi salvo com sucesso!',
  'cards.editParam.successPlural': 'foram salvos com sucesso!',
  'cards.editParam.failure': 'Ocorreu um erro ao enviar as alterações.',

  'cards.revealData.tooltip.title': 'Dados do cartão',
  'cards.revealData.tooltip':
    'Clique para revelar ou ocultar os dados sensíveis.',
  'cards.noCards': 'Nenhum cartão encontrado',
  'cards.noCardsAccount': 'Nenhuma conta encontrada relacionada a esse cartão',

  'cards.sensitiveInfo.show': 'Revelar dados',
  'cards.sensitiveInfo.hide': 'Ocultar dados',

  'cards.newPin': 'Novo PIN',
  'cards.newPinConfirmation': 'Novo PIN (confirmação)',
  'cards.pinMatchError': 'Os valores são diferentes',
  'cards.emptyPinError': 'Campo obrigatório',
  'cards.pinChange': 'Alterar PIN do cartão',
  'cards.pinChange.success': 'PIN alterado com sucesso!',
  'cards.pinChange.failure': 'Erro ao alterar o PIN.',
};

export default cards;
