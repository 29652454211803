const general = {
  male: 'Masculino',
  female: 'Feminino',
  confirm: 'Confirmar',
  confirmationMessage: 'Você deseja confirmar essa operação?',
  submitting: 'Enviando',
  submitted: 'Enviado',
  failedToSubmit: 'Erro ao enviar',
  creditcard: 'Cartão de crédito',
  credit: 'Crédito',
  block: 'Bloquear',
  unblock: 'Desbloquear',
  activate: 'Ativar',
  statements: 'Extrato',
  extract: 'Extrato',
  profile: 'Perfil',
  profileParams: 'Perfil',
  cards: 'Cartões',
  activity: 'Atividade',
  notes: 'Notas',
  pid: 'Confirmação positiva',
  save: 'Salvar',
  cancel: 'Cancelar',
  areYouSure: 'Habilitar modo viagem / Desabilitar modo viagem',
  'general.update.success': 'Os dados foram atualizados',
  'general.update.fail': 'Não foi possível atualizar os dados',
  'general.form.dirty.confirm':
    'O formulário tem modificações que não foram gravadas. Deseja continuar sem salvar?',
  'general.onCall.exit.confirm':
    'Você está em uma chamada. Deseja realmente encerrar a chamada corrente e sair?',
  home: 'Inicial',
  mobileSummary: 'Inicial',
  'general.endCall': 'Encerrar chamada',
  available: 'Disponível',
  totalAvailable: 'Total Disponível',
  availableBalance: 'Saldo Disponível',
  over: 'saldo',
  overdraft_limit: 'Cheque especial',
  minimum: 'Mínimo',
  pay: 'Pagar',
  recharge: 'Recarregar',
  'general.dispute': 'Contestação',
  'general.pid': 'Confirmação Positiva',
  current: 'Atual',
  now: 'agora',
  transaction: 'Transação',
  minimumPayment: 'Pagamento mínimo',
  maximumPayment: 'Pagamento máximo',
  tryAgainLater: 'Por favor, tente novamente mais tarde.',
  open: 'Aberta',
  closed: 'Fechada',
  settled: 'Criada',
  rejected: 'Rejeitada',
  cancelled: 'Cancelada',
  processed: 'Processada',
  refunded: 'Reembolsado',
  refused: 'Recusada',
  paid: 'Paga',
  for: 'para',
  to: 'até',
  dueOn: 'Vence em ',
  programTypeNotFound: 'Não encontrado o tipo de programa',
  days: 'Dias',
  all: 'Todos',
  back: 'Voltar',
  year: 'ano',
  years: 'anos',
  denied: 'Negadas',
  MDR: 'taxa',
  date: 'Data',
  noTransactionsFound: 'Nenhuma transação encontrada.',
  protocol: 'Protocolo',
  'general.address': 'Endereço',
  'general.mainAddress': 'Endereço principal',
  'general.additionalAddress': 'Endereço adicional',
  installments: 'Parcelada em {installments}x',
  over_balance: 'Saldo',
  'general.pagination.previous': 'Anterior',
  'general.pagination.next': 'Próxima',
  'general.pagination.page': 'Página',
  'general.pagination.of': 'de',
};

export default general;
