const timeline = {
  'timeline-event-see-details': 'Ver detalhes',
  'timeline-event-status': 'status',
  'timeline-received-value': 'valor recebido',
  'timeline-event-to': 'para',
  'timeline-event-from': 'de',
  'timeline-event-sent': 'enviado por',
  'timeline-event-discount-pismo': 'desconto Pismo',
  'timeline-event-disputable': 'Informar problema',
  'timeline-event-details': 'Detalhes',
  'timeline-event-in-dispute': 'Transação em análise',
  'timeline-event-autentication': 'Autenticação',
  'timeline-event-tid': 'TID',
  'message-transaction': 'Transação realizada a mais de 90 dias',
  'timeline-event-authorization-id': 'Autorização',

  'timeline-event-payment-made': 'pagamento feito',
  'timeline-event-payment-received': 'pagamento recebido',
  'timeline-event-payment-made-refused': 'pagamento feito recusado',
  'timeline-event-payment-received-refused': 'pagamento recebido recusado',

  'timeline-event-card-end': 'final',
  'timeline-event-transaction-goal': 'finalidade',
  'timeline-event-document': 'documento',
  'timeline-event-document-person': 'documento',
  'timeline-event-document-company': 'cnpj',
  'timeline-event-bank-company': 'instituição',
  'timeline-event-bank-branch': 'agência',
  'timeline-event-bank-account': 'conta',
  'timeline-payment-reason': 'finalidade',
  'timeline-transaction-id': 'id da transação',

  'timeline-event-barcode': 'Código de barras',
  'timeline-event-status-category-CANCELLED': 'boleto cancelado',
  'timeline-event-status-category-BANKSLIP': 'carga via boleto',
  'timeline-event-status-category-OVERDUE': 'boleto vencido',
  'timeline-event-status-category-REGISTERED': 'boleto gerado',
  'timeline-event-status-category-SETTLED': 'boleto pago',
  'timeline-event-due-date-category-CANCELLED': 'cancelado em',
  'timeline-event-due-date-category-OVERDUE': 'vencido em',
  'timeline-event-due-date-category-REGISTERED': 'vencimento',
  'timeline-event-due-date-category-SETTLED': 'vencimento',

  'timeline-value': 'Valor',
  'timeline-discount': 'Desconto',
  'timeline-event-reason': 'motivo',
  'timeline-event-DEBIT': 'débito',
  'timeline-event-DENIED': 'disputa de transação não reconhecida negada',
  'timeline-event-CREDIT': 'crédito',
  'timeline-event-TEMPORARY_ISSUER_LOSS': 'crédito em confiança',
  'timeline-event-CLOSED_APPROVED_TEMPORARY_ISSUER_LOSS':
    'confirmação de crédito em confiança',
  'timeline-event-ANALYSING': 'em análise',
  'timeline-event-OPEN': 'transação não reconhecida',
  'timeline-event-CLOSED_REVERSED': 'estorno de crédito em confiança',
  'timeline-event-CLOSED_ISSUER_LOSS': 'crédito para o cliente',
  'timeline-event-payment-pismo': 'pagamento Pismo',
  'timeline-event-TRANSFER CREDITCARD':
    'Pismo via cartão enviado para {to_name}',
  'event-REVERSAO BLOQUEIO JUDICIAL-CREDIT': 'Credito com bloqueio Judicial',
};

export default timeline;
