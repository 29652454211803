import {
  INTERNATIONAL,
  INTL_ATM_SPEND_LIMIT,
  INTL_ECOM_SPEND_LIMIT,
  INTL_NFC_SPEND_LIMIT,
  INTL_POS_SPEND_LIMIT,
} from 'components/CustomerSpendingLimits/CustomerSpendingLimitsChannel.utils';
import { LocationSearch } from '../clients';

const { term, statementView } = LocationSearch.parsed;

const initialState = {
  customerSearch: {
    result: [],
  },
  root: {},
  user: {
    email: '',
    token: '',
    tenant: '',
    accounts: [],
    roles: [],
    isCustomer: false,
  },
  org: {
    id: '',
    name: '',
    logo: '',
    currency: '',
    currencySymbol: '',
    programs: [],
    programsParametersIds: [],
    orgBaasId: '',
    username: '',
    allowAccountStatusChange: false,
    allowTransferDebit: false,
  },
  call: {
    onCall: false,
    currentProtocol: null,
    init: null,
    timerCount: null,
    respectiveCustomer: {
      customerId: null,
      accountId: null,
    },
  },
  credentials: {
    email: '',
    token: '',
    tenant: '',
    roles: [],
    jwt: {},
    protocol: null,
    status: '',
  },
  customers: {
    search: term || '',
    results: [],
    displayCount: 25,
    selectedResult: 0,
    isLoading: false,
    isFetching: false,
    customerList: [],
  },
  customer: {
    account: {},
    clientId: null,
    entity: {
      type: { entity_type: 'FISICA' },
    },
    program: {},
    programs: [],
    contract: {},
    credit_limits: {},
    accountStatusCustomer: {
      status: null,
      open_due_date: null,
    },
    latestAuthorizations: {
      isLoading: false,
      items: [],
    },
    isAvatarLoading: false,
    avatar: null,
  },
  programSelector: {
    isOpen: false,
  },
  statements: {
    months: [],
    isScrolling: false,
    isLoading: true,
    selectedMonth: {
      index: null,
      statement: {
        id: null,
      },
    },
    currentStatement: {
      currentView: statementView || 'transactions',
      totals: {
        main: {},
        list: [],
        exchange_rate: null,
        international: {},
      },
      transactions: {
        isLoading: true,
        error: false,
        errorMsg: null,
        currentPage: null,
        items: [],
        groups: [],
        pages: null,
        perPage: null,
        totalItems: null,
        selectedTransaction: {
          index: null,
          id: null,
        },
      },
    },
    agreement: {
      isAgree: false,
      isLoading: false,
      isSendingSmsOrEmail: false,
      summary: {
        min_payment_percent: 0,
        total_due_amount: 0,
        min_upfront_amount: 0,
        total_interest_amount_discount: 0,
        statements: [
          {
            amount: 0,
            month: 0,
            year: 0,
          },
        ],
      },
      dueDates: [],
      conditions: {
        total_due_amount: 0,
        min_upfront_amount: 0,
        min_payment_percent: 0,
        upfront_amount: 0,
        financed_amount: 0,
        total_interest_amount_discount: 0,
        first_payment_date: 0,
        fixed_taxes: {
          fixed_iof_rate: 0,
          daily_iof_rate: 0,
        },
        installment_options: [
          {
            number_of_installments: 0,
            installment_amount: 0,
            total_amount: 0,
            last_payment_date: 0,
            interest: {
              interest_rate: 0,
              annual_interest_rate: 0,
              total_interest_amount: 0,
            },
            taxes: {
              total_fixed_iof_amount: 0,
              total_daily_iof_amount: 0,
              total_iof_amount: 0,
            },
            cet: {
              per_year_percentage: 0,
              per_month_percentage: 0,
            },
          },
        ],
      },
      conclusion: {
        bankslip: 0,
        due_date: 0,
        amount: 0,
      },
    },
    downloadStatementPdf: {
      data: {},
    },
  },
  transaction: {
    isLoading: true,
    error: false,
    errorMsg: null,
    transaction: {},
    authorization: {},
    customer: {},
    merchant: {},
  },
  dispute: {
    isLoading: false,
    isLoadingDisputeTree: false,
    isSubmitting: false,
    disagreementOptions: [],
    serviceProblemsOptions: [],
    answers: [],
  },
  payment: {
    isOpen: false,
    dueDate: null,
    amountInput: '',
    sliderInput: 0,
    isFetchingTotalDue: true,
    totalDue: {
      consolidated: {},
    },
    isSendingEmail: false,
    hasSentEmail: false,
    isSendingSMS: false,
    hasSentSMS: false,
    errorState: false,
    error: null,
    minUpfrontAmount: null,
    inputValidate: false,
    isLoading: false,
  },
  splitInvoice: {
    show: false,
    dueDate: null,
    amountInput: '',
    sliderInput: 0,
    isFetchingTotalDue: true,
    totalDue: {
      consolidated: {},
    },
    isSendingEmail: false,
    hasSentEmail: false,
    isSendingSMS: false,
    hasSentSMS: false,
    errorState: false,
    error: null,
    splitOptions: {},
    selectedIndexOption: 0,
    reviewSplitInvoice: false,
  },
  ui: {
    language: null,
    currentRoute: null,
    isMobile: null,
    isMobileKeyboardVisible: null,
    initialDimensions: {
      width: null,
      height: null,
    },
    currentDimensions: {
      width: null,
      height: null,
    },
  },
  profileParams: {
    viewer: 'account',
    menuFixed: false,
    editing: {
      id: null,
      prevValue: 0,
      nextValue: 0,
    },
    dueDateAvaliables: [],
    options: {
      amount: 0,
      available: 0,
      available_monthly: 0,
      available_total_installment: 0,
      available_withdrawal: 0,
      best_transaction_day: 0,
      credits: 0,
      current_balance: 0,
      due_date: 0,
      event_date_hour: 0,
      last_statement: 0,
      minimum_payment: 0,
      monthly: 0,
      total: 0,
      total_installment: 0,
      withdrawal: 0,
      account_status: '',
    },
    parameters: [],
    statuses: [],
    reasons: [],
    accountStatusProfile: null,
    disputeCounts: {
      ANALYSING: 0,
      APPROVED: 0,
      CANCELED: 0,
      DENIED: 0,
      DRAFT: 0,
      OPEN: 0,
    },
    paramsLoading: false,
  },
  cards: {
    list: [],
    groups: [],
    isLoading: true,
    isWalletloadingCompleted: false,
    cardErrorMsg: '',
    addNewCardBtnPosition: null,
  },
  newCard: {
    form: {
      name: '',
      printed_name: '',
      document_number: '',
      gender: '',
      birth_date: '',
    },
    isConfirmationOpen: false,
    isValid: false,
    isSubmitting: false,
    outcome: null,
    reasons: [],
  },
  card: {
    params: [],
    blockCardBtnPosition: null,
    isLoading: false,
    isLoadingPCI: false,
    isOpen: false,
  },
  cardUnblock: {
    isOpen: false,
    isSubmitting: false,
    outcome: null,
  },
  cardTemporaryBlock: {
    isOpen: false,
    isSubmitting: false,
    outcome: null,
  },
  cardStatusChange: {
    isOpen: false,
    isSubmitting: false,
    outcome: null,
    statuses: [],
    selectedStatus: '',
  },
  cardEditParam: {
    isOpen: false,
    isSubmitting: false,
    outcome: null,
    param: {},
  },
  cardPasswordChange: {
    isOpen: false,
    isSubmitting: false,
    showOtpField: false,
  },
  toast: {
    isVisible: false,
    message: null,
    style: null,
  },
  limitProposal: {
    status: {},
    isOpen: false,
    nextLimit: 0,
    nextLimitMasked: '0,00',
    nextLimitSlider: 0,
    isLoading: true,
    isSubmitting: false,
    outcome: null,
    selectedReason: '',
    proposal: {
      org_id: null,
      accounts_limit_proposals_id: null,
      phone_2: null,
      phone_1: null,
      reason: null,
      address: null,
      city: null,
      limit_proposal: null,
      number: null,
      uf: null,
      account_id: null,
      limit: null,
      name: null,
      cpf: null,
      id: null,
      neighborhood: null,
      complement: null,
      email: null,
      status: null,
    },
  },
  attendanceNotes: {
    isVisible: false,
    notes: {},
  },
  callHistory: {
    current_page: 0,
    per_page: 25,
    next_page: null,
    items: [],
    isLoading: false,
    isLoadingMore: false,
    selectedProtocol: null,
  },
  callDetails: {
    has_notes: null,
    initial_date: null,
    protocol: null,
    status: null,
    user: null,
    events: [],
    isLoadingEvents: false,
  },
  modal: {
    isOpen: false,
    isSubmitting: false,
    outcome: null,
    idOpen: 0,
  },
  timeline: {
    current_page: 1,
    per_page: 20,
    items: [],
    pages: 1,
    next_page: false,
    error: false,
    errorMsg: '',
    isLoading: true,
    stoppedPooling: false,
  },
  routeWatcher: null,
  customerAddressesHistory: {
    current_page: 1,
    per_page: 20,
    addresses: [],
    next_page: false,
    error: false,
    errorMsg: '',
    isLoading: false,
  },
  customerPhonesHistory: {
    current_page: 1,
    per_page: 20,
    phones: [],
    next_page: false,
    error: false,
    errorMsg: '',
    isLoading: false,
  },
  recharge: {
    isOpen: false,
    amountInput: '10',
    minAmount: 10,
    isSendingEmail: false,
    hasSentEmail: false,
    isSendingSMS: false,
    hasSentSMS: false,
    errorState: false,
    error: null,
  },
  advanceInvoiceInstallment: {
    isOpen: false,
    isFetching: true,
    invoiceInstallment: {},
    isSubmitting: false,
    errorState: false,
    error: null,
    selectedIndexOption: 0,
  },
  cancelInvoiceInstallment: {
    isOpen: false,
    isFetching: true,
    invoiceInstallment: {},
    isSubmitting: false,
    errorState: false,
    error: null,
    cancelConfirmed: false,
  },
  bankAccounts: {
    limits: {
      limits: [],
      bankSlips: [],
    },
    accountHolders: {},
    isLoading: false,
  },
  rules: {
    travelNotification: false,
    isLoading: true,
    hasError: false,
  },
  searchByCard: {
    search: term || '',
    results: [],
    displayCount: 25,
    selectedResult: 0,
    isLoading: false,
    isFetching: false,
  },
  pid: {
    token: null,
    isLoading: true,
    questions: [],
  },
  spendingLimits: {
    loading: false,
    channels: {
      pos_chip: {
        restrictionActivated: false,
        restrictionId: null,
        limitValue: null,
        dateResetValue: null,
        spendlimitId: null,
        NoChannelLimitSetLength: false,
      },
      pos_nfc: {
        restrictionActivated: false,
        restrictionId: null,
        limitValue: null,
        spendlimitId: null,
        NfcRespObj: {},
        NoChannelLimitSetLength: false,
      },
      ecommerce: {
        restrictionActivated: false,
        restrictionId: null,
        limitValue: null,
        dateResetValue: null,
        spendlimitId: null,
        NoChannelLimitSetLength: false,
      },
      atm: {
        restrictionActivated: false,
        restrictionId: null,
        limitValue: null,
        dateResetValue: null,
        spendlimitId: null,
        NoChannelLimitSetLength: false,
      },
      [INTERNATIONAL]: {
        restrictionActivated: false,
        restrictionId: null,
        limitValue: null,
        spendlimitId: null,
        dateResetValue: null,
      },
      [INTL_ECOM_SPEND_LIMIT]: {
        restrictionActivated: false,
        restrictionId: null,
        limitValue: null,
        spendlimitId: null,
        dateResetValue: null,
      },
      [INTL_POS_SPEND_LIMIT]: {
        restrictionActivated: false,
        restrictionId: null,
        limitValue: null,
        spendlimitId: null,
        dateResetValue: null,
      },
      [INTL_NFC_SPEND_LIMIT]: {
        restrictionActivated: false,
        restrictionId: null,
        spendlimitId: null,
        limitValue: null,
        IntlNfcRespObj: {},
      },
      [INTL_ATM_SPEND_LIMIT]: {
        restrictionActivated: false,
        restrictionId: null,
        limitValue: null,
        spendlimitId: null,
        dateResetValue: null,
      },
    },
  },
  onBoard: {
    isLoading: false,
    hasError: false,
    data: {},
    otpData: {},
    account: {},
    clientId: null,
  },
  internationalOtp: {
    isInternationalOtpOpen: false,
    isSubmitting: false,
    otpdata: {},
  },
  serviceRequests: {
    isLoading: false,
    errorMsg: '',
    serviceRequestsHistory: [],
  },
  AccProgramTypes: {
    AccountProgramTypes: [],
    AccountProgramTypesList: [],
    isLoading: true,
  },
  accessDeniedModal: {
    showBlockedPopUp: false,
    showBlockedPopUpDetails: {},
    showModalLoading: false,
  },
};

export default initialState;
