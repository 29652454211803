const possibleAvatarColors = [
  '#3b4756',
  '#2d4664',
  '#ec8246',
  '#5e749a',
  '#71829d',
  '#a3acbc',
];

export default possibleAvatarColors;
