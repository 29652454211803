const programTypes = {
  CHECKING_ACCOUNT: 'CONTA-CORRENTE',
  CREDIT: 'CREDITO',
  PRE_PAID: 'PRE-PAGO',
  DEBIT: 'DEBITO',
  MERCHANT: 'MERCHANT',
  DEBITOZEROBALANCE: 'DEBITO ZERO-BALANCE',
  CREDITZEROBALANCE: 'CREDITO ZERO-BALANCE',
};

export default programTypes;
