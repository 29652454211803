import React, { useEffect, useState } from 'react';
import Banner from '../../assets/img/banner.png';

export const Welcome = ({ onBoardingConfig }) => {
  const [clientId, setClientId] = useState(null);

  useEffect(() => {
    setClientId(sessionStorage.getItem('clientId'));
  }, []);
  return (
    <section className="onboard-content">
      <div className="onboard-page-title">
        <p>{`Welcome to`}</p>
        <p>{onBoardingConfig?.name}</p>
      </div>
      <div className="welcome-message">
        <p>{`Your Card Management Interface will be available soon, `}</p>
        <p>{`please check back after sometime...`}</p>
      </div>
      {clientId === 'CL_00UTKB' && (
        <div className="card-delivery-info">
          <p>{`Your physical Credit Card will get delivered to your communication address as per Bank's record. Post delivery, please check the invitation mail for card activation process.
          `}</p>
        </div>
      )}

      <div className="onboard-page-img-container">
        <img
          src={onBoardingConfig?.logo?.default}
          alt="banner-img"
          className="onboard-banner-img"
        />
      </div>
    </section>
  );
};
