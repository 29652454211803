import React, { useState } from 'react';
import { CustomerPageWrapper } from 'components';
import './delinquency.scss';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { BiRupee } from 'react-icons/bi';
import { useEffect } from 'react';
import { Accounts } from 'clients';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Loader } from 'components/commons';
import { injectIntl } from 'react-intl';
import { showToast } from 'actions';

const Delinquency = () => {
  const credentials = useSelector((state) => state.credentials);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
    const accId = sessionStorage.getItem('pismo-account-id');
    Accounts.delinquencyDetails(accId, credentials)
      .then((res) => {
        setLoading(false);
        setItems(res.items);
      })
      .catch(() => {
        dispatch(
          showToast({
            message: 'Failed to Fetch Data',
            style: 'error',
          }),
        );
        setLoading(false);
      });
  }, []);

  const formatToIndianCurrency = (number) => {
    const formatter = new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return formatter.format(number);
  };

  return (
    <CustomerPageWrapper>
      <div className="w-100 mw9-ns center-ns pt3-ns  delinquency_result_main">
        <label className="delinquency-heading">Delinquency</label>

        <div className="sub-div-delinquency">
          <div>
            <div className="overdue-card">
              <center>
                <h4>Total overdue amount</h4>
                <div className="rupee-amt">
                  {loading ? (
                    <Loader />
                  ) : items.length > 0 ? (
                    `${formatToIndianCurrency(items[0].overdue_amount)}`
                  ) : (
                    formatToIndianCurrency(0)
                  )}
                </div>
              </center>
            </div>
          </div>
          <div>
            <h5 className="history">History</h5>
            <TableContainer className="custPhones_tbl_container">
              <Table stickyHeader aria-label="sticky table">
                <TableHead className="cust_tbleHead">
                  <TableRow>
                    <TableCell align="left" className="th-cell">
                      statement cycle
                    </TableCell>
                    <TableCell align="left" className="th-cell">
                      cycle date
                    </TableCell>
                    <TableCell align="left" className="th-cell">
                      bucket amount
                    </TableCell>
                    <TableCell align="left" className="th-cell">
                      bucket balance
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="cust_tbleBody">
                  {loading ? (
                    <TableCell colSpan={4}>
                      <center>
                        <Loader />
                      </center>
                    </TableCell>
                  ) : items.length > 0 ? (
                    items.map((item, i) => {
                      return (
                        <TableRow key={i}>
                          <TableCell align="left" className="td-cell">
                            Cycle {item?.statement_cycle}
                          </TableCell>
                          <TableCell align="left" className="td-cell">
                            {moment(item?.cycle_closing_date).format(
                              'DD MMM YYYY',
                            )}
                          </TableCell>
                          <TableCell align="left" className="td-cell">
                            {formatToIndianCurrency(item?.bucket_amount)}
                          </TableCell>
                          <TableCell align="left" className="td-cell">
                            {formatToIndianCurrency(item?.bucket_balance)}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableCell align="left" colSpan={4}>
                      <center>No Data Found</center>
                    </TableCell>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    </CustomerPageWrapper>
  );
};

export default Delinquency;
